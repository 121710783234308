import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Deal from "../deal/Deal";
import ShortDeal from "../shortDeal/ShortDeal";

/* Slider */
import Slider from "react-slick";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ShortDealsCarrousel = ({ postsIds, dealsTitle }) => {
  const [deals, setDeals] = useState([]);
  const navigate = useNavigate();

  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "20px",
    infinite: true,
    slidesToShow: 2,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    // Usar una función asíncrona para obtener los deals basados en los postIds
    const fetchDeals = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/instagram-posts/`, {
          params: {
            postsIds: postsIds.join(","),
          },
        });
        setDeals(response.data);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchDeals();
  }, [postsIds]);

  useEffect(() => {}, [deals]);

  return (
    <div className="">
      {/* Deals Title */}
      <div className="xs:mb-4">
        <h2 className="xs:text-[20px] md:text-[24px] font-bold xs:ml-2">
          {dealsTitle}
        </h2>
      </div>
      {/* Desktop Mode  */}
      <div className="xs:hidden md:flex flex-between flex-wrap gap-3">
        {deals?.map((deal, index) => {
          if (deal.status !== "deleted") {
            return (
              <div key={index} className="">
                <ShortDeal deal={deal} />
              </div>
            );
          }
        })}
      </div>
      {/* Responsive Mode */}
      <div className="md:hidden quick-promo-slider slider-container flex flex-col gap-3">
        <Slider {...settings} className="">
          {deals?.map((deal, index) => {
            if (deal.status !== "deleted") {
              return <ShortDeal key={index} deal={deal} />;
            }
          })}
        </Slider>
      </div>
    </div>
  );
};

export default ShortDealsCarrousel;
