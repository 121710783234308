import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ShortDeal = ({ deal }) => {
  /* flex md:w-[49%] rounded-md shadow gap-1 p-4 */
  const navigate = useNavigate();

  const handleDealClick = (businessId) => {
    navigate(`/business/${businessId}`);
  };

  console.log("deal", deal);

  return (
    <div
      className="relative flex flex-col gap-1 xs:w-[212px] xs:max-h-[] rounded-md xs:mx-2 md:mx-0  cursor-pointer"
      onClick={() => {
        handleDealClick(deal.instagram_account_id);
      }}
    >
      {/* business picture */}
      <div className="flex max-h-[120px] object-contain">
        <img
          className="rounded-md"
          src={deal.thumbnail_url || deal.media_url}
          alt=""
        />
      </div>
      {/* Deal Details */}
      <div className="flex flex-col ">
        {/*Deal title*/}
        <div className="">
          <span className="xs:text-[12px] md:text-[13px] font-bold">
            {deal.name}
          </span>
        </div>
        {/* Deal Description */}
        <div className="xs:text-[12px] md:text-[13px] text-[#000000]">
          <span>{deal.deal_short_description}</span>
        </div>
      </div>
    </div>
  );
};

export default ShortDeal;
